<template>
    <article
        tabindex="0"
        :class="['collapse', { hide: !state, active: state }]"
    >
        <header class="header" @click.prevent.stop="handleTitleClick">
            <slot name="header" />
            <icon name="arrow-up" />
        </header>

        <div ref="contentWrapper" class="content-wrapper">
            <div ref="content" :class="['content']">
                <slot name="content" />
            </div>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        state: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:state'],

    data() {
        return {}
    },

    watch: {
        '$resize.value'() {
            this.updateMaxHeight()
        },
    },

    mounted() {
        this.updateMaxHeight()
    },

    methods: {
        handleTitleClick() {
            this.$emit('update:state', !this.state)
        },

        updateMaxHeight() {
            const contentWrapper = this.$refs.contentWrapper
            const content = this.$refs.content

            const height = content.clientHeight
            contentWrapper.style.maxHeight = height + 'px'
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/config';

.collapse {
    --collapse-time: 0.4s;
    margin-bottom: 1rem;
    @include shadow-x-light;
    background-color: theme('colors.ocean.100');
    color: theme('colors.slate.900');
    // transition: color 0.25s calc(var(--collapse-time) / 2),
    //     background-color 0.25s calc(var(--collapse-time) / 2);

    &.hide {
        .header {
            .icon {
                transform: translate(0%, -50%) rotate(180deg);
            }
        }

        .content-wrapper {
            visibility: hidden;
            max-height: 0 !important;
            transition-delay: 0s, var(--collapse-time);
        }

        .content {
            opacity: 0;
        }
    }
}

.header {
    position: relative;
    padding: 0.75rem 1rem;
    padding-right: 2.5rem;
    user-select: none;
    cursor: pointer;
    @apply text-md;
    @apply font-display;
    @apply font-medium;

    .icon {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        width: 1.75rem;
        transform: translate(0%, -50%) rotate(0deg);
        transform-origin: center center;
        transition: transform var(--collapse-time) 0s
            theme('transitionTimingFunction.easeOutQuad');
    }
}

.content-wrapper {
    visibility: visible;
    overflow: hidden;

    transition: max-height var(--collapse-time) 0s
            theme('transitionTimingFunction.easeOutQuad'),
        visibility 0s 0s;
}

.content {
    display: block !important;
    padding: 0.125rem 1rem 0.75rem;
    margin-bottom: -1rem;
    opacity: 1;
    @apply text-sm;
    @apply font-semibold;
    transition: opacity calc(var(--collapse-time) / 2);

    :deep(p) {
        margin-bottom: 1rem;
    }

    :deep(a) {
        @include text-link;
    }
}

@screen sm {
    .header {
        @apply text-lg;
    }

    .content {
        @apply text-md;
        @apply font-normal;
    }
}

@screen md {
    .header {
        padding: 1rem 1.5rem;

        .icon {
            right: 1rem;
        }
    }

    .content {
        padding: 0.125rem 1.5rem 1rem;
        @apply font-semibold;
    }
}

@screen lg {
    .header {
        padding: 1.5rem 2rem;
        @apply text-xl;

        .icon {
            right: 1.5rem;
        }
    }

    .content {
        padding: 0.125rem 2rem 1.5rem;
        @apply font-normal;
    }
}
</style>
